import {http} from "http/index";
import qs from "utils/query";

import {
  formatBranches,
  formatAvailableBranchesList,
  branchInfoFormatter,
  branchInfoForEditFormatter,
  overviewFormatter,
} from "./formatters";

export const create = async data => http.post("/company/branches", data);

export const getList = async agencyId =>
  http.get(`/company/branches${qs.stringify({agencyId})}`).then(formatBranches);

export const getBranchOverview = async branchId =>
  http.get(`company/branches/${branchId}/overview`).then(overviewFormatter);

export const getBranchInfo = async ({branchId, forEdit}) =>
  forEdit
    ? http.get(`company/branches/${branchId}`).then(branchInfoForEditFormatter)
    : http.get(`company/branches/${branchId}`).then(branchInfoFormatter);

export const getAvailableBranches = async () =>
  http.get("/company/branches/info").then(formatAvailableBranchesList);

export const getBranchConnections = async renterId =>
  http
    .get(`/agent/branch-connections/${qs.stringify({renterId})}`)
    .then(formatAvailableBranchesList);

export const edit = async (data, branchId) => http.put(`/company/branches/${branchId}`, data);

export const getAgentBranches = async () => http.get("/company/branches/info");

export const getAgentBranchesForRequestFullReferencing = async () =>
  http.get("/company/branches/info").then(formatAvailableBranchesList);
