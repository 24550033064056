import {createSlice} from "@reduxjs/toolkit";
import {Toaster} from "@findyourcanopy/canopy-ui";
import {FORM_ERROR} from "final-form";

import {
  getAcceptedList,
  getInvitedList,
  sendReminder,
  cancelInvite,
  markAsFavorite,
  markAsUnFavorite,
  getFavouritesList,
} from "http/rentPassport";
import {
  getAcceptedList as getBranchScreeningAcceptedList,
  getInvitedList as getBranchScreeningInvitedList,
  getFavouritesList as getBranchScreeningFavouritesList,
} from "http/branch-screening";
import {changeBranchConnectionsTenancyStatusRequest} from "http/connections";
import {loadingFailed} from "redux/helpers/actions";
import {extractError} from "redux/helpers";
import {passportCondition} from "constants/rentPassport";
import {logout} from "redux/auth/actions";
import analytics from "services/analytics";

import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  reducers: {
    getRentPassportListRequest: handlers.getRentPassportListRequest,
    getRentPassportListSuccess: handlers.getRentPassportListSuccess,
    getRentPassportListFailure: loadingFailed,
    resendInviteSuccess: handlers.resendInviteSuccess,
    toggleFavorite: handlers.toggleFavorite,
    removeFavorite: handlers.removeFavorite,
    tenancyStatusChangeSuccess: handlers.tenancyStatusChangeSuccess,
  },
  extraReducers: {
    [logout]: handlers.clearState,
  },
  initialState,
  name: "rentPassportList",
});

const changeList = ({data, userId}) => {
  const newList = [...data.list];

  const stateElement = newList.find(el => el.id === userId);
  const updatedElementIndex = newList.findIndex(el => el.id === userId);
  const updatedElement = {...stateElement};

  updatedElement.numberOfReminders += 1;

  newList[updatedElementIndex] = updatedElement;

  return {list: newList, total: data.total};
};

const changeListTenancyStatus = ({data, connectionId, tenancyStatus}) => {
  const newList = [...data.list];

  const stateElement = newList.find(
    el => el.connectionId === connectionId || el.branchConnectionId === connectionId,
  );
  const updatedElementIndex = newList.findIndex(
    el => el.connectionId === connectionId || el.branchConnectionId === connectionId,
  );
  const updatedElement = {
    ...stateElement,
    tenancyStatus,
  };

  newList[updatedElementIndex] = updatedElement;

  return {list: newList, total: data.total};
};

const changeAfterCancelInvite = ({data, userId}) => {
  const newList = [...data.list];

  const updatedElementIndex = newList.findIndex(el => el.id === userId);

  newList.splice(updatedElementIndex, 1);

  return {list: newList, total: data.total - 1};
};

const showFormError = error => ({[FORM_ERROR]: extractError(error)});

const funcConfig = {
  [passportCondition.ACCEPTED]: getAcceptedList,
  [passportCondition.FAVOURITES]: getFavouritesList,
  [passportCondition.PENDING]: getInvitedList,
};

const branchScreeningFuncConfig = {
  [passportCondition.ACCEPTED]: getBranchScreeningAcceptedList,
  [passportCondition.FAVOURITES]: getBranchScreeningFavouritesList,
  [passportCondition.PENDING]: getBranchScreeningInvitedList,
};

export const getRentPassportList = ({branchConnections, branchId, status}) => async dispatch => {
  try {
    dispatch(actions.getRentPassportListRequest());

    const requestFunc = branchConnections ? branchScreeningFuncConfig[status] : funcConfig[status];

    const data = await requestFunc({branchId});

    dispatch(actions.getRentPassportListSuccess(data));
  } catch (error) {
    dispatch(actions.getRentPassportListFailure(extractError(error)));
  }
};

export const changeTenancyStatus = values => async (dispatch, getState) => {
  const {data} = getState().rentPassportList;
  const {tenancyStatusChanged} = getState().locales.translations.modules.rentPassport.list.dialog;
  const {userId} = getState().auth.profile.agent;
  const request = {...values, userId};

  try {
    await changeBranchConnectionsTenancyStatusRequest(request);

    dispatch(
      actions.tenancyStatusChangeSuccess(
        changeListTenancyStatus({
          data,
          connectionId: request.branchConnectionId,
          tenancyStatus: request.tenancyStatus,
        }),
      ),
    );
    Toaster.toastConfig.showSuccess({title: tenancyStatusChanged});

    return null;
  } catch (error) {
    return Toaster.toastConfig.showError(error);
  }
};
export const resendInvite = id => async (dispatch, getState) => {
  const {data} = getState().rentPassportList;
  const {reminderSent} = getState().locales.translations.modules.rentPassport.list.dialog;

  try {
    await sendReminder(id);
    dispatch(actions.resendInviteSuccess(changeList({data, userId: id})));
    Toaster.toastConfig.showSuccess({title: reminderSent});

    return null;
  } catch (error) {
    return showFormError(error);
  }
};

export const cancelUserInvite = id => async (dispatch, getState) => {
  const {data} = getState().rentPassportList;
  const {
    cancelledInvite,
  } = getState().locales.translations.modules.rentPassport.list.cancelInviteDialog;

  try {
    await cancelInvite(id);
    dispatch(actions.resendInviteSuccess(changeAfterCancelInvite({data, userId: id})));
    Toaster.toastConfig.showSuccess({title: cancelledInvite});

    return null;
  } catch (error) {
    return showFormError(error);
  }
};

export const toggleFavorite = ({renterId, favorites}) => async dispatch => {
  const func = favorites ? markAsUnFavorite : markAsFavorite;

  try {
    dispatch(actions.toggleFavorite({id: renterId}));

    await func({renterId});
  } catch (error) {
    analytics.logErrorWithLevel("ToastOverlay", error);
    Toaster.toastConfig.showError({title: extractError(error)});
    dispatch(actions.toggleFavorite());
  }
};

export const removeFavorite = ({renterId}) => async dispatch => {
  try {
    dispatch(actions.removeFavorite({id: renterId}));

    await markAsUnFavorite({renterId});
  } catch (error) {
    analytics.logErrorWithLevel("ToastOverlay", error);
    Toaster.toastConfig.showError({title: extractError(error)});
  }
};

export default reducer;
